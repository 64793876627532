/* Global root settings for overflow control */
:root {
  --page-max-width: 100vw;
  --content-max-width: 1000px;
}

/* Root level styles to prevent horizontal overflow */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100vw;  /* Force viewport width */
  max-width: 100vw;
  overflow-x: hidden !important;  /* Force no horizontal scroll */
  position: relative;
  overscroll-behavior: none;  /* Prevent bounce effects */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: auto; /* Force instant scrolling */
  overflow-x: hidden;
  max-width: 100%;
}

/* Ensure root container stays within viewport */
#root {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden !important;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
}

/* Ensure all images and media don't overflow */
img, video, canvas, svg {
  max-width: 100%;
  height: auto;
}

/* Ensure all elements adhere to box model */
div, section, article, aside, footer, header, main, nav {
  box-sizing: border-box;
  max-width: 100vw;
}

/* Prevent unwanted scroll on mobile */
@media (max-width: 768px) {
  html, body {
    touch-action: pan-y;
    -webkit-overflow-scrolling: touch;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Force scroll container to fill viewport */
.content {
  min-height: 100vh;
  width: 100%;
}

/* Hide scrollbar if desired */
/* Uncomment if you want to hide scrollbars while maintaining scroll functionality
html::-webkit-scrollbar,
body::-webkit-scrollbar {
  display: none;
}

html, body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
*/

/* Prevent scroll anchoring which can interfere with manual scroll positioning */
@supports (overflow-anchor: none) {
  * {
    overflow-anchor: none;
  }
}

/* Ensure sections fill width properly */
section, main, div {
  max-width: 100%;
}
